import * as yup from "yup";

export const workflowConfigSchema = yup.object().shape({
  enabled: yup.boolean().required("Enabled status is required"),

  entry_pipeline_step: yup
    .string()
    .required("Entry pipeline step is required")
    .trim()
    .min(1, "Entry pipeline step cannot be empty"),

  auto_progress: yup.boolean().required("Auto progress status is required"),

  next_pipeline_step: yup
    .string()
    .required("Next pipeline step is required")
    .trim()
    .min(1, "Next pipeline step cannot be empty"),

  action_prompt: yup.string().required("Action prompt is required").trim().min(1, "Action prompt cannot be empty"),

  result_comment_prompt: yup
    .string()
    .required("Result comment prompt is required")
    .trim()
    .min(1, "Result comment prompt cannot be empty"),

  strictness_level: yup
    .number()
    .required("Strictness level is required")
    .min(1, "Strictness level must be at least 1")
    .max(3, "Strictness level must not exceed 3")
    .integer("Strictness level must be an integer"),

  auto_reject: yup.boolean().required("Auto reject status is required"),

  rejection_pipeline_step: yup.string().required("Rejection pipeline step is required"), // Allows empty string
});
