import { useSearchParams } from "react-router-dom";
import { PAGE_SIZE } from "../../constants/pagination.constants.ts";

interface UsePaginationProps {
  defaultPage?: number;
  defaultSize?: number;
  defaultSortBy?: string;
  defaultSortDirection?: "ASC" | "DESC";
}

export const usePagination = ({
  defaultPage = 0,
  defaultSize = PAGE_SIZE,
  defaultSortBy,
  defaultSortDirection,
}: UsePaginationProps = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get("page")) || defaultPage;
  const size = Number(searchParams.get("size")) || defaultSize;
  const sortBy = searchParams.get("sortBy") || defaultSortBy;
  const sortDirection = (searchParams.get("sortDirection") as "ASC" | "DESC") || defaultSortDirection;

  const setPage = (newPage: number) => {
    searchParams.set("page", newPage.toString());
    setSearchParams(searchParams);
  };

  const setPageSize = (newSize: number) => {
    searchParams.set("size", newSize.toString());
    searchParams.set("page", "0");
    setSearchParams(searchParams);
  };

  const setSort = (field: string, direction: "ASC" | "DESC") => {
    searchParams.set("sortBy", field);
    searchParams.set("sortDirection", direction);
    searchParams.set("page", "0");
    setSearchParams(searchParams);
  };

  return {
    page,
    size,
    sortBy,
    sortDirection,
    setPage,
    setPageSize,
    setSort,
  };
};
