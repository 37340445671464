import { Keys } from "./keys.ts";
import axios, { type AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import type { ResponseErrorInterface } from "../interfaces/ResponseError.interface.ts";
import type { CreatePlatformRequest, UpdatePlatformRequest } from "../interfaces/platformsResponse.interface.ts";

interface Props {
  onError: (error: AxiosError<ResponseErrorInterface>) => void;
  onSuccess: () => void;
}

export const usePlatformsMutationHook = ({ onError, onSuccess }: Props) => {
  return useMutation({
    mutationKey: [Keys.PLATFORM_UPDATE],
    mutationFn: async (body: UpdatePlatformRequest | CreatePlatformRequest) => {
      const res = await axios({
        data: body,
        url: "/api/platforms",
        method: "id" in body && body?.id ? "put" : "post",
      });
      return res.data;
    },
    onError: (error: AxiosError<ResponseErrorInterface>) => onError(error),
    onSuccess,
  });
};
