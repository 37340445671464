import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { PipelineStep } from "../interfaces/pipelineStep.interface.ts";

export const useJobPipelineStepsHook = (id?: string) => {
  return useQuery<PipelineStep[]>({
    queryKey: ["job-pipeline-steps", id],
    queryFn: async () => {
      const res = await axios.get<PipelineStep[]>(`/api/jobs/${id}/pipeline-steps`);
      return res.data;
    },
    enabled: !!id,
  });
};
