import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { AssessmentInterface } from "../interfaces/assessment.interface.ts";

export const useCandidateAssessHook = (id?: string) => {
  return useQuery<AssessmentInterface[]>({
    queryKey: [Keys.JOB, id],
    queryFn: async () => {
      const res = await axios.get<AssessmentInterface[]>(`/api/candidates/${id}/assessments`);
      return res.data;
    },
    enabled: !!id,
  });
};
