// src/components/form/Switch.tsx
import type { FC } from "react";
import type { UseFormRegister, UseFormWatch } from "react-hook-form";
import { Switch as HeadlessSwitch } from "@headlessui/react";
import type { WorkflowConfig } from "../../interfaces/workflowConfig.interface";

interface Props {
  label: string;
  name: keyof WorkflowConfig;
  register: UseFormRegister<WorkflowConfig>;
  watch: UseFormWatch<WorkflowConfig>;
  description?: string;
}

export const Switch: FC<Props> = ({ label, name, register, watch, description }) => {
  const value = watch(name) as boolean;
  const { onChange, ...rest } = register(name);

  return (
    <HeadlessSwitch.Group>
      <div className="flex items-center">
        <HeadlessSwitch
          checked={value}
          onChange={(checked: boolean) => {
            onChange({
              target: {
                name,
                value: checked,
              },
            });
          }}
          className={`
            group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full 
            border-2 border-transparent bg-gray-200 transition-colors duration-200 
            ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 
            focus:ring-offset-2 data-[checked]:bg-indigo-600
          `}
          {...rest}
        >
          <span className="sr-only">{label}</span>
          <span
            className="pointer-events-none relative inline-block size-5 transform
              rounded-full bg-white shadow ring-0 transition duration-200
              ease-in-out group-data-[checked]:translate-x-5"
          >
            <span
              aria-hidden="true"
              className="absolute inset-0 flex size-full items-center justify-center
                transition-opacity duration-200 ease-in group-data-[checked]:opacity-0
                group-data-[checked]:duration-100 group-data-[checked]:ease-out"
            >
              {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
              <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-400">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              aria-hidden="true"
              className="absolute inset-0 flex size-full items-center justify-center
                opacity-0 transition-opacity duration-100 ease-out
                group-data-[checked]:opacity-100 group-data-[checked]:duration-200
                group-data-[checked]:ease-in"
            >
              {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
              <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-indigo-600">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </HeadlessSwitch>
        <div className="ml-3">
          <HeadlessSwitch.Label className="text-sm font-medium text-gray-900">{label}</HeadlessSwitch.Label>
          {description && (
            <HeadlessSwitch.Description className="text-sm text-gray-500">{description}</HeadlessSwitch.Description>
          )}
        </div>
      </div>
    </HeadlessSwitch.Group>
  );
};
