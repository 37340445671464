import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { CountryQueryParams } from "../interfaces/localizationQueryParams.interface";
import type { Country } from "../interfaces/country.interface.ts";

export const useCountriesHook = (params?: CountryQueryParams) => {
  return useQuery<Country[]>({
    queryKey: ["countries", params],
    queryFn: async () => {
      const { data } = await axios.get("/api/localization/countries", {
        params,
      });
      return data;
    },
  });
};
