// src/components/form/FormActions.tsx
import type { FC } from "react";

interface Props {
  onReset: () => void;
}

export const FormActions: FC<Props> = ({ onReset }) => (
  <div className="flex gap-4">
    <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
      Save Configuration
    </button>
    <button type="button" onClick={onReset} className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">
      Reset
    </button>
  </div>
);
