// src/components/form/TextArea.tsx
import type { FC } from "react";
import type { UseFormRegister } from "react-hook-form";
import type { FieldError } from "react-hook-form";
import { v6 as uuid } from "uuid";

interface Props {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  error?: FieldError;
  rows?: number;
  description?: string;
}

export const TextArea: FC<Props> = ({ label, name, register, error, rows = 3, description }) => {
  const id = `${uuid()}-${name}`;
  return (
    <div>
      <label htmlFor={id} className="block text-sm/6 font-medium text-gray-900">
        {label}
      </label>
      <textarea
        {...register(name)}
        id={id}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
        rows={rows}
      />
      {description && (
        <p id={`${id}-description`} className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
      {error && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {error.message}
        </p>
      )}
    </div>
  );
};
