import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { JobsResponseInterface } from "../interfaces/jobsResponse.interface.ts";

export const useJobsListHook = () => {
  return useQuery<JobsResponseInterface>({
    queryKey: [Keys.JOBS],
    queryFn: async () => {
      const res = await axios.get<JobsResponseInterface>("/api/jobs");
      return res.data;
    },
  });
};
