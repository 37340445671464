// src/components/form/FormSection.tsx
import type { FC, ReactNode } from "react";

interface Props {
  title: string;
  children: ReactNode;
}

export const FormSection: FC<Props> = ({ title, children }) => (
  <div className="flex flex-col gap-4">
    <h3 className="text-xl">{title}</h3>
    {children}
  </div>
);
