import type { FC } from "react";
import type { UseFormRegister, FieldError } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { DevicePhoneMobileIcon } from "@heroicons/react/16/solid";

interface Props {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  error?: FieldError;
  description?: string;
  placeholder?: string;
  className?: string;
  value?: string;
}

export const Phone: FC<Props> = ({
  label,
  name,
  register,
  error,
  description,
  placeholder = label,
  className = "",
}) => {
  const id = `${uuid()}-${name}`;
  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm/6 font-medium text-gray-900">
        {label}
      </label>
      <div className="mt-2 grid grid-cols-1">
        <input
          type="tel"
          {...register?.(name)}
          id={id}
          placeholder={placeholder}
          className="col-start-1 row-start-1 block w-full rounded-md bg-white py-1.5 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:pl-9 sm:text-sm/6"
        />
        <DevicePhoneMobileIcon
          aria-hidden="true"
          className="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400 sm:size-4"
        />
      </div>
      {description && (
        <p id={`${id}-description`} className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
      {error && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {error.message}
        </p>
      )}
    </div>
  );
};
