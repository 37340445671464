import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { TimezoneQueryParams } from "../interfaces/localizationQueryParams.interface";
import type { Timezone } from "../interfaces/timezone.interface.ts";

export const useTimezonesHook = (params?: TimezoneQueryParams) => {
  return useQuery<Timezone[]>({
    queryKey: ["timezones", params],
    queryFn: async () => {
      const { data } = await axios.get("/api/localization/timezones", {
        params,
      });
      return data;
    },
  });
};
