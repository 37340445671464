import type { FC } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import type { PaginationMetadata } from "../../interfaces/paginatedMetadata.interface.ts";

interface PaginationControlsProps {
  metadata: PaginationMetadata;
  onPageChange: (page: number) => void;
  className?: string;
}

export const PaginationControls: FC<PaginationControlsProps> = ({ metadata, onPageChange, className = "" }) => {
  const handlePreviousPage = () => {
    onPageChange(metadata.current_page - 1);
  };

  const handleNextPage = () => {
    onPageChange(metadata.current_page + 1);
  };

  const startItem = metadata.total_elements > 0 ? metadata.current_page * metadata.page_size + 1 : 0;

  const endItem = Math.min((metadata.current_page + 1) * metadata.page_size, metadata.total_elements);

  return (
    <div className={`flex items-center justify-between border-t border-gray-200 bg-white py-3 ${className}`}>
      {/* Mobile version */}
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          type="button"
          onClick={handlePreviousPage}
          disabled={!metadata.has_previous}
          className={`relative inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold ${
            metadata.has_previous
              ? "text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              : "text-gray-300 cursor-not-allowed"
          }`}
        >
          Previous
        </button>
        <button
          type="button"
          onClick={handleNextPage}
          disabled={!metadata.has_next}
          className={`relative ml-3 inline-flex items-center rounded-md px-4 py-2 text-sm font-semibold ${
            metadata.has_next
              ? "text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              : "text-gray-300 cursor-not-allowed"
          }`}
        >
          Next
        </button>
      </div>

      {/* Desktop version */}
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startItem}</span> to <span className="font-medium">{endItem}</span>{" "}
            of <span className="font-medium">{metadata.total_elements}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              type="button"
              onClick={handlePreviousPage}
              disabled={!metadata.has_previous}
              className={`relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                metadata.has_previous ? "hover:bg-gray-50" : "cursor-not-allowed opacity-50"
              }`}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300">
              Page {metadata.current_page + 1} of {metadata.total_pages}
            </span>

            <button
              type="button"
              onClick={handleNextPage}
              disabled={!metadata.has_next}
              className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 ${
                metadata.has_next ? "hover:bg-gray-50" : "cursor-not-allowed opacity-50"
              }`}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};
