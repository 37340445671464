import { Keys } from "./keys.ts";
import axios from "axios";
import type { AxiosError, AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import type { ResponseErrorInterface } from "../interfaces/ResponseError.interface.ts";
import type { WorkflowConfigForm } from "../views/workflowConfigurator.view.tsx";
import type { JobSettings } from "../interfaces/jobSettings.interface.ts";

interface Props {
  jobId?: string;
  onError: (error: AxiosError<ResponseErrorInterface>) => void;
  onSuccess: () => void;
}

export const useJobSettingsWorkflowConfigMutationHook = ({ jobId, onError, onSuccess }: Props) => {
  return useMutation({
    mutationKey: [Keys.JOB_SETTINGS_WORKFLOW_CONFIG_MUTATION],
    mutationFn: async (body: WorkflowConfigForm) => {
      const res = await axios<WorkflowConfigForm, AxiosResponse<JobSettings>>({
        data: body,
        url: `/api/job-settings/${jobId}/config`,
        method: "put",
      });
      return res.data;
    },
    onError: (error: AxiosError<ResponseErrorInterface>) => onError(error),
    onSuccess,
  });
};
