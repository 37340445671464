import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { JobInterface } from "../interfaces/job.interface.ts";

export const useJobHook = (id?: string) => {
  return useQuery<JobInterface>({
    queryKey: [Keys.JOB, id],
    queryFn: async () => {
      const res = await axios.get<JobInterface>(`/api/jobs/${id}`);
      return res.data;
    },
    enabled: !!id,
  });
};
