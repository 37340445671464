import type { FC } from "react";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";
import { useJobsListHook } from "../api/useJobsListHook.ts";
import { Link } from "react-router-dom";
import { RoutesConstants } from "../constants/routes.constants.ts";

export const JobsPage: FC = () => {
  const { data: jobs, isLoading } = useJobsListHook();

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <ul className="divide-y divide-gray-100">
            {isLoading && <LoaderSpinner />}
            {jobs?.jobs?.map((job) => {
              return (
                <li key={job.id} className="flex items-center justify-between gap-x-6 py-5">
                  <div className="min-w-0">
                    <div className="flex items-center gap-x-3">
                      <span className="text-sm/6 font-semibold text-gray-900">
                        <Link to={`${RoutesConstants.JOBS}/${job.id}`}>{job.name}</Link>
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </main>
  );
};
