import type { FC } from "react";
import type { WorkflowConfig } from "../interfaces/workflowConfig.interface.ts";
import type { PipelineStep } from "../interfaces/pipelineStep.interface.ts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { workflowConfigSchema } from "../schema/workflowConfiguration.schema.ts";
import { Checkbox } from "../components/form/Checkbox";
import { Select } from "../components/form/Select";
import { TextArea } from "../components/form/TextArea";
import { FormActions } from "../components/form/FormActions";
import { Switch } from "../components/form/Switch.tsx";
import { useStableDebounce } from "../utils/useStableDebounce.hook.ts";

export interface WorkflowConfigForm {
  name: string;
  config: WorkflowConfig;
}

interface Props {
  name: string;
  title: string;
  description: string;
  config: WorkflowConfig;
  onSubmit?: (data: WorkflowConfigForm) => void;
  onChange?: (data: WorkflowConfigForm) => void;
  onError?: (error: string) => void;
  pipelineSteps?: PipelineStep[];
}

const STRICTNESS_LEVELS = [
  { value: 1, label: "Low" },
  { value: 2, label: "Normal" },
  { value: 3, label: "Strict" },
];

export const WorkflowConfiguratorView: FC<Props> = ({
  name,
  config,
  onSubmit,
  onError,
  onChange,
  title,
  description,
  pipelineSteps = [],
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<WorkflowConfig>({
    resolver: yupResolver(workflowConfigSchema),
    defaultValues: config,
  });

  /// -------

  const formValues = watch();
  useStableDebounce(
    (values: WorkflowConfig) => {
      onChange?.({ config: values, name });
    },
    1000,
    formValues as WorkflowConfig,
  );

  // useEffect(() => {
  //   const subscription = watch((value) => {
  //     onChange?.({ config: value as WorkflowConfig, name });
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch, onChange, name]);

  const handleFormSubmit = async (data: WorkflowConfig) => {
    try {
      onSubmit?.({ config: data as WorkflowConfig, name });
    } catch (error) {
      onError?.(error instanceof Error ? error.message : "An error occurred");
    }
  };

  const pipelineStepOptions = pipelineSteps.map((step) => ({
    value: step.name,
    label: step.name,
  }));

  const isEnabled = watch("enabled");

  return (
    <div
      className={`p-4 border-l-4 transition-colors duration-200 ${
        isEnabled ? "border-l-green-500" : "border-l-gray-200"
      }`}
    >
      {/* Enable Switch */}
      <div className="mb-4">
        <Switch label="Enable Workflow" name="enabled" register={register} watch={watch} />
      </div>

      {/* Title and Description */}
      <div className="mb-6">
        <h2 className="text-2xl mb-2">{title}</h2>
        <p className="text-gray-600">{description}</p>
      </div>

      <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
        {/* Entry Step and Strictness Level */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Select
            label="Entry Pipeline Step"
            name="entry_pipeline_step"
            options={pipelineStepOptions}
            register={register}
            error={errors.entry_pipeline_step}
            emptyOption="Select Entry Step"
          />
          <Select
            label="Strictness Level"
            name="strictness_level"
            options={STRICTNESS_LEVELS}
            register={register}
            error={errors.strictness_level}
          />
        </div>

        {/* Auto-progress and Auto-reject Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <Checkbox label="Auto-progress to Next Step on success" name="auto_progress" register={register} />
            <Select
              label="Next Pipeline Step"
              name="next_pipeline_step"
              options={pipelineStepOptions}
              register={register}
              error={errors.next_pipeline_step}
              emptyOption="Select Next Step"
            />
          </div>
          <div className="space-y-4">
            <Checkbox label="Auto-Reject Candidate" name="auto_reject" register={register} />
            <Select
              label="Rejection Pipeline Step"
              name="rejection_pipeline_step"
              options={pipelineStepOptions}
              register={register}
              error={errors.rejection_pipeline_step}
              emptyOption="Select Rejection Step"
            />
          </div>
        </div>

        {/* Prompts Section */}
        <div className="space-y-6">
          <TextArea label="Prompt for Action" name="action_prompt" register={register} error={errors.action_prompt} />
          <TextArea
            label="Prompt for Result Comments"
            name="result_comment_prompt"
            register={register}
            error={errors.result_comment_prompt}
          />
        </div>

        {onSubmit && <FormActions onReset={() => reset(config)} />}
      </form>
    </div>
  );
};
