import axios, { type AxiosError, type AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import type { ResponseErrorInterface } from "../interfaces/ResponseError.interface.ts";
import type { ProfileUpdateRequest } from "../interfaces/profileUpdateRequest.interface.ts";
import type { Profile } from "../interfaces/profile.interface.ts";

interface Props {
  onError: (error: AxiosError<ResponseErrorInterface>) => void;
  onSuccess: () => void;
}

export const useProfileMutationHook = ({ onError, onSuccess }: Props) => {
  return useMutation({
    mutationKey: ["profile"],
    mutationFn: async (body: ProfileUpdateRequest) => {
      const res = await axios<ProfileUpdateRequest, AxiosResponse<Profile>>({
        data: body,
        url: "/api/profile",
        method: "put",
      });
      return res.data;
    },
    onError: (error: AxiosError<ResponseErrorInterface>) => onError(error),
    onSuccess,
  });
};
