import type { FC } from "react";
import { useCandidateAssessHook } from "../api/useCandidateAssessHook.ts";
import { useParams } from "react-router-dom";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";

export const CandidatePage: FC = () => {
  const { id: candidateId } = useParams();
  const { data: assessments, isLoading } = useCandidateAssessHook(candidateId);

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {isLoading && <LoaderSpinner />}
          {!isLoading && assessments && (
            <pre className="text-wrap	pl-3">
              <code>{JSON.stringify(assessments, null, 2)}</code>
            </pre>
          )}
        </div>
      </div>
    </main>
  );
};
