import SpaceMonkeySvg from "../assets/space-monkey.svg";
import { Link } from "react-router-dom";

export const ErrorPage = () => (
  <div className="fixed bg-white z-50 top-0 bottom-0 left-0 right-0 flex flex-col space-y-6 justify-center items-center mx-auto py-24">
    <img src={SpaceMonkeySvg} alt="SpaceMonkey" className="max-w-[1200px] w-auto mx-2" />
    <h2 className="text-lg text-gray-900 font-bold">Oops! Something went wrong</h2>
    <p className="text-sm text-gray-500 max-w-xl text-center">Don’t worry! The code monkeys are working on it!</p>
    <Link to="/" className="btn">
      Back to Dashboard
    </Link>
  </div>
);
