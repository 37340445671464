import { Auth0Context, useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEffect, useState } from "react";
import type { ReactNode, FC } from "react";
import { TopNavView } from "./topNav.view.tsx";
import { LoadingView } from "./loading.view.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Props {
  children: ReactNode;
}

export const LayoutView: FC<Props> = ({ children }) => {
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0(Auth0Context);

  const [isTokenSet, setIsTokenSet] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        },
        detailedResponse: true,
      }).then((response) => {
        axios.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;
        axios.defaults.baseURL = import.meta.env.VITE_API_SERVER_URL;
        setIsTokenSet(true);
      });
    } else {
      setIsTokenSet(false);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!isAuthenticated || !user || !isTokenSet) {
    return <>⛔️ NOT LOGGED IN</>;
  }

  return (
    <div>
      <TopNavView />
      <div className="py-10">{children}</div>
      <ToastContainer />
    </div>
  );
};
