import type { FC } from "react";
import { useState } from "react";
import { usePlatformsListHook } from "../api/usePlatformsListHook.ts";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";
import { Keys } from "../api/keys.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "react-toastify";
import ConfirmModal from "../components/modal/confirm.component.tsx";
import HangingDudeSvg from "../assets/guy-hanging.svg";
import IconAddSvg from "../assets/icon-add.svg";

export const DashboardPage: FC = () => {
  const { data: platforms, isLoading } = usePlatformsListHook();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const queryClient = useQueryClient();

  const resolveDetails = (driver: string) => {
    const details = platforms?.drivers.find((d) => d.name === driver);
    if (!details?.name) {
      return undefined;
    }
    return details;
  };

  const { mutate } = useMutation({
    mutationKey: [Keys.PLATFORMS],
    mutationFn: async (body: { id: string }) => {
      const res = await axios.delete("/api/platforms", { data: body });
      return res.data;
    },
    onSuccess: () => {
      // Boom baby!
      void queryClient.invalidateQueries({ queryKey: [Keys.PLATFORMS] });
      toast.success("Deleted successfully");
      setIsOpenConfirmModal(false);
      queryClient.invalidateQueries({ queryKey: [Keys.PLATFORMS] });
    },
  });

  const deletePlatform = (platformId: string) => {
    setIsOpenConfirmModal(true);
    setSelectedPlatform(platformId);
  };

  const setOpenConfirmModal = (isOpen: boolean, confirm: boolean) => {
    if (confirm) {
      mutate({
        id: selectedPlatform,
      });
    } else {
      setIsOpenConfirmModal(isOpen);
    }
  };

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          {/* --- */}
          <div className="flex flex-row justify-end mb-8">
            <Link to="/platform/new" className="btn">
              Add Platform
            </Link>
          </div>

          <div>
            <ul className="divide-y divide-gray-100">
              {isLoading && <LoaderSpinner />}

              {!isLoading && (!platforms?.platforms || platforms?.platforms.length === 0) && (
                <div className="flex flex-col space-y-6 justify-between items-center max-w-2xl mx-auto py-24">
                  <img src={HangingDudeSvg} alt="" className="max-w-[600px] w-auto mx-2" />
                  <h2 className="text-lg text-gray-900 font-bold">You don't have any platforms connected!</h2>
                  <p className="text-sm text-gray-500">
                    Connect your first platform to initiate job and candidate synchronization. Once connected, you can
                    configure assessments, test them securely, and enable syncing to automatically update all results in
                    your main ATS!
                  </p>
                  <Link to="/platform/new" className="btn">
                    <img src={IconAddSvg} alt="" />
                    Add Platform
                  </Link>
                </div>
              )}

              {platforms?.platforms?.map((platform) => {
                const resolvedDetails = resolveDetails(platform.driver);
                return (
                  <li key={platform.id} className="flex items-center justify-between gap-x-6 py-5">
                    <div className="min-w-0">
                      <div className="flex items-center gap-x-3">
                        <span className="w-12 h-12">
                          {resolvedDetails?.image_url && (
                            <img src={resolvedDetails?.image_url} alt={resolvedDetails?.nice_name ?? platform.driver} />
                          )}
                        </span>
                        <span className="text-sm/6 font-semibold text-gray-900">
                          {resolvedDetails?.nice_name ?? platform.driver}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <Link
                        to={`/platform/${platform.id}`}
                        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                      >
                        Edit
                      </Link>
                      <Menu as="div" className="relative flex-none">
                        <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                          <span className="sr-only">Open options</span>
                          <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                        </MenuButton>
                        <MenuItems
                          transition
                          className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          <MenuItem>
                            <Link
                              to={`/platform/${platform.id}`}
                              className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                            >
                              Edit
                            </Link>
                          </MenuItem>
                          <MenuItem>
                            <a
                              href={void 0}
                              onClick={() => deletePlatform(platform.id)}
                              className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                            >
                              Delete
                            </a>
                          </MenuItem>
                        </MenuItems>
                      </Menu>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <ConfirmModal
        title="Delete platform?"
        content="Are you sure you want to disconnect this platform? It will stop AI Recruiter from working with the jobs related to this connection."
        open={isOpenConfirmModal}
        setOpen={(value, confirm) => setOpenConfirmModal(value, confirm)}
      />
    </main>
  );
};
