import { useCallback, useEffect, useRef } from "react";

export function useStableDebounce<T>(callback: (value: T) => void, delay: number, value: T) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(callback);
  const previousValueRef = useRef<T>();
  const isFirstRender = useRef(true);

  // Update callback ref when callback changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debouncedCallback = useCallback(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      previousValueRef.current = value;
      return;
    }

    if (JSON.stringify(value) === JSON.stringify(previousValueRef.current)) {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      previousValueRef.current = value;
      callbackRef.current(value);
    }, delay);
  }, [value, delay]);

  // Call debounced callback when value changes
  useEffect(() => {
    debouncedCallback();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [debouncedCallback]);
}
