// src/views/jobGlobalSettings.view.tsx
import type { FC } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import type { JobSettings } from "../interfaces/jobSettings.interface";
import { jobSettingsSchema } from "../schema/jobSettings.schema";
import { Select } from "../components/form/Select";
import { FormSection } from "../components/form/FormSection";
import { FormActions } from "../components/form/FormActions";
import type { Country } from "../interfaces/country.interface.ts";
import type { Language } from "../interfaces/language.interface.ts";
import { useTimezonesHook } from "../api/useTimezonesHook.ts";

export type TJobSettings = Pick<
  JobSettings,
  | "candidate_language"
  | "ats_language"
  | "activity_country"
  | "activity_timezone"
  | "activity_hour_start"
  | "activity_hour_end"
>;

interface Props {
  settings: TJobSettings;
  onSubmit?: (data: TJobSettings) => void;
  onChange?: (data: TJobSettings) => void;
  onError?: (error: string) => void;
  title?: string;
  description?: string;
  countries?: Array<Country>;
  languages?: Array<Language>;
}

const HOURS_OPTIONS = Array.from({ length: 24 }, (_, i) => ({
  value: i,
  label: `${i}:00`,
}));

export const JobGlobalSettingsView: FC<Props> = ({
  settings,
  onSubmit,
  onChange,
  onError,
  title = "Miscellaneous Settings",
  description = "This section provides options to customize recruitment workflows based on language preferences, time zones, and interaction scheduling. Additionally, it defines when and how the AI interacts with candidates. Use this section to set global preferences, ensuring the recruitment process is tailored to the candidate’s location and communication preferences. Customize triggers, actions, and rules for broader workflow controls.",
  countries = [],
  languages = [],
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<TJobSettings>({
    resolver: yupResolver(jobSettingsSchema),
    defaultValues: settings,
  });

  useEffect(() => {
    const subscription = watch((value) => {
      onChange?.(value as TJobSettings);
    });
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  const handleFormSubmit = async (data: TJobSettings) => {
    try {
      onSubmit?.(data);
    } catch (error) {
      onError?.(error instanceof Error ? error.message : "An error occurred");
    }
  };

  const languageOptions = languages.map((lang) => ({
    value: lang.code,
    label: lang.name.toString(),
  }));

  const countryOptions = countries.map((country) => ({
    value: country.id,
    label: country.name.toString(),
  }));

  const selectedCountry = watch("activity_country");
  const { data: timezones, isSuccess: isSuccessTimezones } = useTimezonesHook({
    countryId: selectedCountry ? Number.parseInt(selectedCountry, 10) : undefined,
  });

  const timezoneOptions =
    timezones?.map((timezone) => ({
      value: timezone.id,
      label: timezone.name.toString(),
    })) ?? [];

  return (
    <div className="p-4">
      <div className="mb-6">
        <h2 className="text-2xl mb-2">{title}</h2>
        <p className="text-gray-600">{description}</p>
      </div>

      <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
        <FormSection title="Language Settings">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Select
              label="Candidate Language"
              name="candidate_language"
              options={languageOptions}
              register={register}
              error={errors.candidate_language}
              emptyOption="Select Language"
            />
            <Select
              label="ATS Language"
              name="ats_language"
              options={languageOptions}
              register={register}
              error={errors.ats_language}
              emptyOption="Select Language"
            />
          </div>
        </FormSection>

        <FormSection title="Activity Settings">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Select
              label="Country"
              name="activity_country"
              options={countryOptions}
              register={register}
              error={errors.activity_country}
              emptyOption="Select Country"
            />
            {isSuccessTimezones && (
              <Select
                label="Timezone"
                name="activity_timezone"
                options={timezoneOptions}
                register={register}
                error={errors.activity_timezone}
                emptyOption="Select Timezone"
              />
            )}
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Select
              label="Start Hour"
              name="activity_hour_start"
              options={HOURS_OPTIONS}
              register={register}
              error={errors.activity_hour_start}
              emptyOption="Select AI Shift Start"
            />
            <Select
              label="End Hour (0-23)"
              name="activity_hour_end"
              options={HOURS_OPTIONS}
              register={register}
              error={errors.activity_hour_end}
              emptyOption="Select AI Shift End"
            />
          </div>
        </FormSection>

        {onSubmit && <FormActions onReset={() => reset(settings)} />}
      </form>
    </div>
  );
};
