// src/components/form/Select.tsx
import type { FC } from "react";
import type { UseFormRegister } from "react-hook-form";
import type { FieldError } from "react-hook-form";
import { v6 as uuid } from "uuid";

interface Option {
  value: string | number;
  label: string;
}

interface Props {
  label: string;
  name: string;
  options: Option[];
  register: UseFormRegister<any>;
  error?: FieldError;
  emptyOption?: string;
  description?: string;
  className?: string;
}

export const Select: FC<Props> = ({
  label,
  name,
  options,
  register,
  error,
  emptyOption,
  description,
  className = "",
}) => {
  const id = `${uuid()}-${name}`;

  return (
    <div className={className}>
      <label htmlFor={id} className="block text-sm/6 font-medium text-gray-900">
        {label}
      </label>
      <select
        {...register(name)}
        id={id}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
      >
        {emptyOption && <option value="">{emptyOption}</option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {description && (
        <p id={`${id}-description`} className="mt-2 text-sm text-gray-500">
          {description}
        </p>
      )}
      {error && (
        <p id="email-error" className="mt-2 text-sm text-red-600">
          {error.message}
        </p>
      )}
    </div>
  );
};
