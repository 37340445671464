import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { JobSettings } from "../interfaces/jobSettings.interface.ts";

export const useJobSettingsHook = (id?: string) => {
  return useQuery<JobSettings>({
    queryKey: [Keys.JOB_SETTINGS, id],
    queryFn: async () => {
      const res = await axios.get<JobSettings>(`/api/job-settings/${id}`);
      return res.data;
    },
    enabled: !!id,
  });
};
