import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { LanguageQueryParams } from "../interfaces/localizationQueryParams.interface";
import type { Language } from "../interfaces/language.interface.ts";

export const useLanguagesHook = (params?: LanguageQueryParams) => {
  return useQuery<Language[]>({
    queryKey: ["languages", params],
    queryFn: async () => {
      const { data } = await axios.get("/api/localization/languages", {
        params,
      });
      return data;
    },
  });
};
