import { Keys } from "./keys.ts";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import type { Platform } from "../interfaces/platform.interface.ts";

export const usePlatformHook = (id?: string) => {
  return useQuery<Platform>({
    queryKey: [`${Keys.PLATFORM}-${id}`],
    queryFn: async () => {
      const res = await axios.get<Platform>(`/api/platforms/${id}`);
      return res.data;
    },
    enabled: !!id,
  });
};
