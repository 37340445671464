import { withAuthenticationRequired } from "@auth0/auth0-react";
import type { FC, ComponentType } from "react";
import { LoadingView } from "../views/loading.view.tsx";

interface AuthenticationGuardProps {
  component: ComponentType;
}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingView />,
  });

  return <Component />;
};
