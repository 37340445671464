export enum Keys {
  PLATFORMS = "platforms",
  PLATFORM = "platform",
  PLATFORM_UPDATE = "platform-update",
  DRIVERS = "drivers",
  JOBS = "jobs",
  JOB = "job",
  JOB_SETTINGS = "job-settings",
  CANDIDATES = "candidates",
  JOB_SETTINGS_MUTATION = "JOB_SETTINGS_MUTATION",
  JOB_SETTINGS_WORKFLOW_CONFIG_MUTATION = "JOB_SETTINS_WORKFLOW_CONFIG_MUTATION",
}
