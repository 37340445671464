import { Controller, useForm } from "react-hook-form";
import { LoaderSpinner } from "../components/loader/loaderSpinner.component.tsx";
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import type { CreatePlatformRequest, UpdatePlatformRequest } from "../interfaces/platformsResponse.interface.ts";
import type { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import type { Driver } from "../interfaces/driver.interface.ts";
import { toast } from "react-toastify";
import type { ResponseErrorInterface } from "../interfaces/ResponseError.interface.ts";
import { usePlatformsMutationHook } from "../api/usePlatformsMutationHook.ts";
import { usePlatformHook } from "../api/usePlatformHook.ts";
import { usePlatformsListHook } from "../api/usePlatformsListHook.ts";
import { useDriversHook } from "../api/useDrivers.hook.ts";
import { RoutesConstants } from "../constants/routes.constants.ts";
import ReactMarkdown from "react-markdown";

type FormData = {
  driver?: Driver;
} & {
  [key: string]: string;
};

export const PlatformPage = () => {
  const { data, isLoading } = usePlatformsListHook();
  const [fields, setFields] = useState<Array<string>>([]);
  const navigate = useNavigate();
  const { data: drivers, isLoading: isDriversLoading } = useDriversHook();
  const { id: platformId } = useParams();
  const { data: platform, isLoading: isPlatformLoading } = usePlatformHook(platformId);
  const { mutate } = usePlatformsMutationHook({
    onError: (error: AxiosError<ResponseErrorInterface>) => toast.error(error?.response?.data?.message),
    onSuccess: () => {
      toast.success("Information saved successfully");
      navigate(RoutesConstants.PLATFORMS);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm();

  const onSubmit = (formData: FormData) => {
    if (!formData.driver?.name) {
      return;
    }
    const body: CreatePlatformRequest | UpdatePlatformRequest = {
      driver: formData.driver?.name,
      config: Object.fromEntries(fields.map((fKey) => [fKey, formData[fKey]])),
    };
    if (platformId) {
      (body as UpdatePlatformRequest).id = platformId;
    }
    mutate(body);
  };

  const selected = watch("driver", {
    name: "",
    nice_name: "Select Driver",
    image_url: "",
    fields: {},
  } as Driver);

  useEffect(() => {
    if (platform && drivers) {
      const driver = platform.driver;
      const selectedDriver = drivers.drivers.find((d: Driver) => d.name === driver);
      if (selectedDriver) {
        setValue("driver", selectedDriver);
        for (const key of Object.keys(selectedDriver.fields)) {
          setValue(key, platform.config[key]);
        }
        setFields(Object.keys(selectedDriver.fields));
      }
    }
  }, [drivers, platform, setValue]);

  return (
    <main>
      <div className="mx-auto max-w-7xl pb-12 sm:px-6 lg:px-8 px-4">
        <div className="rounded-lg bg-white px-5 py-24 shadow sm:px-6">
          <div className="mx-auto w-full max-w-md">
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-6">
              {(isLoading || isPlatformLoading || isDriversLoading) && <LoaderSpinner />}
              <div>
                <Controller
                  name="driver"
                  control={control}
                  defaultValue={selected?.nice_name}
                  rules={{ validate: (value) => value !== selected.nice_name }}
                  render={({ field }) => (
                    <Listbox
                      value={field.value}
                      disabled={Boolean(platform?.id)}
                      onChange={(value) => {
                        field.onChange(value); // Update React Hook Form's state
                        setFields(Object.keys(value?.fields));
                      }}
                      name={field.name}
                    >
                      <Label className="block text-sm/6 font-medium text-gray-900">Platform Driver</Label>
                      <div className="relative mt-2">
                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm/6">
                          <span className={`block truncate ${platform?.id ? "text-gray-400" : ""}`}>
                            {field.value === "Select Driver" ? "Select Driver" : field.value?.nice_name}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon aria-hidden="true" className="size-5 text-gray-400" />
                          </span>
                        </ListboxButton>
                        <ListboxOptions
                          transition
                          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                        >
                          {data?.drivers.map((driver: Driver) => (
                            <ListboxOption
                              key={driver.name}
                              value={driver}
                              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                            >
                              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                {driver.nice_name}
                              </span>

                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                <CheckIcon aria-hidden="true" className="size-5" />
                              </span>
                            </ListboxOption>
                          ))}
                        </ListboxOptions>
                      </div>
                    </Listbox>
                  )}
                />
                {errors.driver && <span className="p-1 text-base text-red-400">Driver is required</span>}
              </div>

              {selected?.fields &&
                Object.keys(selected?.fields).map((key) => (
                  <div key={key}>
                    <label htmlFor="apiKey" className="block text-sm/6 font-medium text-gray-900">
                      {selected?.fields[key].label}
                    </label>
                    <div className="mt-2">
                      <input
                        id={key}
                        {...register(`${key}`, {
                          required: "required",
                        })}
                        type={selected?.fields[key].type}
                        placeholder=""
                        className="block w-full rounded-md border-0 py-3 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                      />
                    </div>
                    {selected?.fields[key].description && (
                      <span className="text-helper">
                        <ReactMarkdown>{selected?.fields[key].description}</ReactMarkdown>
                      </span>
                    )}
                    {errors[key] && (
                      <span className="p-1 text-base text-red-400">{errors[key]?.message?.toString()}</span>
                    )}
                  </div>
                ))}
              <input type="submit" className="btn block mx-auto w-[120px] h-[40px]" />
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};
